<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div>
    <div class="bg-black fixed top-0 left-0 w-full z-30 text-white bg-opacity-40 rounded-b-2xl fade-in" v-if="outletData !== null">
      <el-row type="flex" justify="justify-between" align="middle" class="p-2.5">
        <el-col class="hidden md:block">
          <div class="font-bold uppercase">Өөрчлөлтийг хадгалах</div>
        </el-col>
        <el-col class="flex flex-row justify-end">
          <el-button type="danger" @click="centerDialogVisible = true" v-if="$root.outlet !== null && outletData._id !== $root.outlet._id">Устгах</el-button>
          <el-button @click="outletData = null">Болих</el-button>
          <el-button type="success" @click="save(outletData)">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="panel-item" v-loading="loading">
      <el-form
        :model="outletData"
        ref="outletDataFormRef"
        :rules="outletDataFormRules"
        v-if="outletData && currentOutlet"
      >
        <div class="panel-item">
          <el-row :gutter="40" class="mainContainer">
            <el-col :span="8" class="text-center mainSection" v-loading="load">
              <el-form-item v-if="outletData" prop="img_url" label="Рестораны нүүр зураг(600*400pxls)">
                <div class="overlay">
                  <el-upload
                    v-model="outletData.img_url"
                    class="avatar-uploader"
                    action=""
                    fit="cover"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="file => uploadImage(file)"
                  >
                    <el-image
                      v-if="outletData.img_url"
                      :src="outletData.img_url"
                      class="avatar"
                      style="width:100%"
                    />
                    <span>
                      <i
                        v-if="outletData.img_url"
                        slot="default"
                        class="el-icon-plus mb20 mt20"
                      >
                        Зураг солих
                      </i>
                      <i
                        v-else
                        class="el-icon-plus avatar-uploader-icon"
                        style="width:300px; height:200px;"
                      >
                        Зураг оруулах
                      </i>
                    </span>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="text-center mainSection" v-loading="loader">
              <el-form-item v-if="outletData">
                <div class="overlay">
                  <span slot="label">
                    Рестораны лого(400*400pxls)
                    <el-tooltip
                      class="item cursor-pointer"
                      effect="dark"
                      content="Хэрэглэгч өөрөө очиж авах үед энэ лого харагдана"
                      placement="top"
                    >
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </span>
                  <el-upload
                    v-model="outletData.logo_url"
                    class="avatar-uploader mt15"
                    action=""
                    fit="cover"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="file => uploadPickUpImage(file)"
                  >
                    <el-image
                      v-if="outletData.logo_url"
                      :src="outletData.logo_url"
                      class="avatar"
                      style="width: 100%; display: block;"
                    />
                    <span>
                      <i
                        v-if="outletData.logo_url"
                        slot="default"
                        class="el-icon-plus mt20"
                      >
                        Зураг солих
                      </i>
                      <i v-else class="el-icon-plus avatar-uploader-icon">
                        Зураг оруулах
                      </i>
                    </span>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="text-right history">
              <!-- <el-select v-model="outletData.is_active" placeholder="Нээлттэй эсэх" size="small" class="mr10">
                <el-option
                  v-for="(item, indexItem) in openOptions"
                  :key="indexItem"
                  :label="item.label"
                  :value="item.value"
                  disabled>
                </el-option>
              </el-select> -->
              <el-button
                size="small"
                type="success"
                @click="showHistoryListDialog()"
              >
                Түүх харах
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div>
          <div style="display: flex; gap: 1em; flex-direction: row; margin-top: 20px;">
            <div class="active"></div>
            <header>Ерөнхий мэдээлэл</header>
          </div>
          <el-row :gutter="40" class="mainContainer">
            <el-col :span="17" class="mainSection">
              <div class="panel-item">
                <el-row :gutter="20">
                  <el-col :span="12" class="mainSection">
                    <el-form-item prop="full_name_mon" label="Рестораны монгол нэр">
                      <el-input class="text" :disabled="!$root.isAdmin" v-model="outletData.full_name_mon"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" class="mainSection">
                    <el-form-item prop="full_name_eng" label="Рестораны англи нэр">
                      <el-input class="text" :disabled="!$root.isAdmin" v-model="outletData.full_name_eng"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12" class="mainSection">
                    <el-form-item
                      prop="description"
                      label="Рестораны танилцуулга(Монгол)"
                    >
                      <el-input
                        class="text"
                        v-model="outletData.description"
                        type="textarea"
                        :rows="2"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" class="mainSection">
                    <el-form-item
                      prop="description_eng"
                      label="Рестораны танилцуулга(Англи)"
                    >
                      <el-input
                        v-model="outletData.description_eng"
                        type="textarea"
                        :rows="2"
                        class="text"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="panel-item">
                <div style="display: flex; gap: 1em; flex-direction: row; margin-top: 20px;">
                  <div class="active"></div>
                  <header>Хаяг <span class="text-sm">(Харилцагч та хаягаа сонгоорой)</span></header>
                </div>
                <div style="margin-bottom:1%; display:flex; flex-wrap:wrap;">
                  <el-select
                    style="width: 100%;"
                    v-model="outletData.address_id"
                    filterable
                    placeholder="Хайх"
                    remote
                    reserve-keyword
                    :remote-method="searchAddress"
                    @change="changeAddress(outletData.address_id)"
                  >
                    <el-option
                      v-for="(addrData, index) in addressList"
                      :key="index"
                      :label="addrData.full_address"
                      :value="addrData._id"
                    />
                  </el-select>
                  <div class="google-map mt10">
                    <gmap-map
                      :center="location"
                      :zoom="12"
                      :options="mapOptions"
                      style="width: 100%; height: 100%; margin-top: 5px"
                    >
                      <gmap-marker
                        :position="location"
                        :clickable="true"
                        @dragend="changeLoc($event)"
                        :draggable="true"
                      />
                    </gmap-map>
                  </div>
                  <div class="mt-2.5 flex flex-row justify-center items-center w-full">
                    <span class="text-sm text-red-600">
                      Газрын зураг дээрх зүү таны салбарын байршилтай таарч байгаа эсэхийг шалгаарай.
                    ​</span>
                  </div>
                </div>
              </div>
              <div class="panel-item">
               <div style="display: flex; gap: 1em; flex-direction: row; margin-top: 20px;">
                <div class="active"></div>
                <header>Үйлчилгээ / Ангилал</header>
              </div>
                <el-row :gutter="20" class="mainContainer">
                  <el-col :span="12" class="mainSection">
                    <el-form-item size="small">
                      <span class="text">Үйлчилгээнүүд</span>
                      <el-select
                        v-model="outletData.service_list"
                        placeholder="Сонгох"
                        class="dblock text"
                        clearable
                        disabled
                        collapse-tags
                        filterable
                        multiple
                        @change="onChangeServices"
                      >
                        <el-option
                          v-for="service in $root.serviceList.filter(el => el.name_eng !== 'delivery' && el.name_eng !== 'discounted food')"
                          :label="service.name_mon"
                          :value="service.name_eng.toLowerCase()"
                          :key="service.name_eng.toLowerCase()"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" class="mainSection">
                    <el-row>
                      <el-col :span="24" class="mainSection">
                        <el-form-item
                          label="Үндсэн ангилал"
                          size="small"
                          prop="main_category_mon"
                        >
                          <el-select
                            v-model="outletData.main_category_mon"
                            :disabled="!$root.isAdmin"
                            multiple
                            filterable
                            placeholder="Сонгох"
                            class="dblock"
                            collapse-tags
                            @change="categoryChange()"
                            >
                            <el-option-group
                              v-for="service in $root.serviceList"
                              :key="service.name_eng"
                              :label="service.name_mon.toUpperCase()"
                            >
                              <el-option
                                v-for="(item, index) in mainCategoryList.filter(
                                  el => el.service === service.name_eng
                                )"
                                :key="index"
                                :label="item.name_mon"
                                :value="item.name_mon"
                              >
                                <span style="float: left">
                                  {{ item.name_mon }}
                                </span>
                                <span
                                  style="float: right; color: #8492a6; font-size: 12px"
                                >
                                  {{ service.name_mon }}
                                </span>
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mainContainer">
                  <el-col :span="12" class="mainSection">
                    <el-row>
                      <el-col :span="24" class="mainSection">
                        <el-form-item
                          label="Дотоод ангилал"
                          size="small"
                          ><br />
                          <br />
                            <draggable
                              :list="outletData.menu_categories"
                              class="list-group"
                              ghost-class="ghost"
                              :move="checkMove"
                              @start="dragging = true"
                              @end="dragging = false"
                            >
                              <el-tag
                                class="list-group-item"
                                v-for="(tag, mTagIndex) in outletData.menu_categories"
                                :disable-transitions="true"
                                :key="mTagIndex"
                                type="info"
                                closable
                                @close="handleCloseMenuCategoryDrag(tag)"
                              >
                                {{ tag.menu_category_mon }}
                              </el-tag>
                            </draggable>
                          <el-select
                            v-model="outletData.menu_categories"
                            multiple
                            value-key="menu_category_mon"
                            filterable
                            collapse-tags
                            placeholder="Сонгох"
                          >
                            <el-option-group
                              v-for="service in $root.serviceList"
                              :key="service.name_eng"
                              :label="service.name_mon.toUpperCase()"
                            >
                              <el-option
                                v-for="(item, index) in menuCategoryList.filter(
                                  el => el.service === service.name_eng
                                )"
                                :key="index"
                                :label="item.menu_category_mon"
                                :value="item"
                              >
                                <span style="float: left">
                                  {{ item.menu_category_mon }}
                                </span>
                                <span
                                  style="float: right; color: #8492a6; font-size: 13px"
                                >
                                  {{ item.service }}
                                </span>
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="12" class="mainSection">
                    <div v-for="(category, categoryIndex) in outletData.main_category_mon" :key="categoryIndex">
                      <el-card v-for="(item, itemIndex) in mainCategoryList.filter(el => el.name_mon === category)" :key="itemIndex" :body-style="{ padding: '0px' }">
                        <el-row type="flex" justify="center" align="middle">
                          <el-image :src="item.img_url" style="width: 80px; height: 80px"></el-image>
                          <span class="ml10">{{item.name_eng}}</span>
                        </el-row>
                      </el-card>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="mainContainer">
                  <div style="display: flex; gap: 1em; flex-direction: row; margin-top: 20px;">
                  <div class="active"></div>
                  <header>Харилцагчийн мэдээлэл</header>
                </div>
                  <el-table
                    :data="outletData.phone_list"
                    :show-header="false"
                    :cell-style="{padding: '2px'}"
                  >
                    <el-table-column
                      prop="name"
                    >
                      <template slot-scope="scope">
                          <span>{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="phone">
                      <template slot-scope="scope">
                        <el-form-item
                          label="Утас"
                          :prop="'phone_list.' + scope.$index + '.phone'"
                          :rules="phoneListRules.phone"
                        >
                          <el-input
                            size="small"
                            placeholder="99119911"
                            style="text-align:center"
                            type="number"
                            :min="8"
                            :max="8"
                            v-model.number="scope.row.phone"
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column prop="email">
                      <template slot-scope="scope">
                        <el-form-item
                          label="И-мэйл"
                          :prop="'phone_list.' + scope.$index + '.email'"
                          :rules="phoneListRules.email"
                        >
                          <el-input
                            size="small"
                            placeholder="toktok@gmail.com"
                            style="text-align:center"
                            v-model.number="scope.row.email"
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </div>
            </el-col>
            <el-col :span="7" class="mainSection">
              <el-row>
                <el-col :span="24">
                  <el-form-item prop="email" label="И-мэйл">
                    <el-input size="small" v-model="outletData.email" disabled class="text"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Хувь хүн/Компани">
                    <el-input size="medium" v-model="outletData.vat_type" class="text" disabled />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Регистр">
                    <el-input size="medium" v-model="outletData.vat_regno" class="text" disabled />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Банкны нэр">
                    <el-input v-if="bankCodes.length > 0" class="text" :value="bankCodes.find(el => el.bank_code == outletData.bank_code) ? bankCodes.find(el => el.bank_code == outletData.bank_code).bank_name_mon : outletData.bank_code" disabled placeholder="Банкны нэр" size="medium"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Дансны нэр">
                    <el-input size="medium" class="text" v-model="outletData.bank_account_name" disabled />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="Дансны дугаар">
                    <el-input size="medium" class="text" v-model="outletData.bank_account_number" disabled />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item :label="outletData.commission_type === 'percent' ? 'Шимтгэлийн хувь' : 'Шимтгэлийн дүн'">
                <el-input
                  v-model="outletData.percent"
                  disabled
                  class="text"
                >
                <template slot="append" v-if="outletData.commission_type === 'percent'">%</template>
                <template slot="append" v-else-if="outletData.commission_type === 'fixed'">₮</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="outletData.is_verified" :disabled="!$root.isAdmin" class="text">
                  Гэрээ байгуулсан эсэх
                </el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="outletData.is_active" :disabled="!$root.isAdmin" class="text">
                  Нээлттэй эсэх
                </el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-checkbox
                  :disabled="!$root.isAdmin"
                  v-model="outletData.is_pickup"
                  @change="pickUp(outletData.is_pickup)"
                  class="text"
                >
                  Очиж авах
                </el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-checkbox
                  v-model="outletData.is_preOrder"
                  :disabled="!$root.isAdmin"
                  class="text"
                >
                  Урьдчилсан захиалга
                </el-checkbox>
              </el-form-item>
              <el-form-item v-if="outletData.is_preOrder">
              <el-switch
                :disabled="!$root.isAdmin"
                v-model="outletData.is_preorder_by_date"
                size="small"
                active-text="Он сар сонгох"
                inactive-text="Өдөрөө оруулах"
              />
            </el-form-item>
              <el-form-item v-if="!outletData.is_preorder_by_date && outletData.is_preOrder" label="Урьдчилсан захиалгын өдөр">
                <el-input-number
                  v-model="outletData.preorder_days"
                  :disabled="!$root.isAdmin"
                  class="text"
                  :min="1"
                  :max="15"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item  v-else-if="outletData.is_preOrder" label="Урьдчилсан захиалгын өдөр">
                <el-date-picker
                style="width: auto"
                  v-model="outletData.preorder_start_date"
                  :disabled="!$root.isAdmin"
                  type="date"
                  placeholder="Start Date"
                  format="yyyy/MM/dd"
                />
                <el-date-picker
                style="margin-top: 10px; width: auto"
                  v-model="outletData.preorder_end_date"
                  :disabled="!$root.isAdmin"
                  type="date"
                  placeholder="End Date"
                  format="yyyy/MM/dd"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row class="mb20 saveAndDeleteBtn" :gutter="20">
            <el-col :span="24" class="text-right">
              <el-button type="danger" @click="centerDialogVisible = true" v-if="$root.outlet !== null && outletData._id !== $root.outlet._id">Устгах</el-button>
              <el-button type="success"  @click="save(outletData)"
                >Хадгалах</el-button
              >
            </el-col>
          </el-row> -->
          <!-- <div class="deleteSaveBtn">
              <button @click="centerDialogVisible = true" v-if="$root.outlet !== null && outletData._id !== $root.outlet._id" type="button" class="deleteButton">
              <span style="font-size: 18px; color: white;">Устгах</span>
            </button>
            <button type="button" class="saveButton mt20" @click="save(outletData)">
              <span style="font-size: 18px;">Хадгалах</span>
            </button>
          </div> -->
        </div>
      </el-form>
      <p v-else class="text-center pt50 pb50 text-secondary">
        Харилцагчаа сонгоно уу
      </p>
    </div>
    <HistoryListDialog
      :isShow="isShowHistoryListDialog"
      :outletId="currentOutlet ? currentOutlet._id : null"
      :closeFunction="closeHistoryListDialog"
    />
    <el-dialog
      :title="currentOutlet && currentOutlet.full_name_mon + ' харилцагчийг устгахдаа итгэлтэй байна уу?'"
      :visible.sync="centerDialogVisible"
      width="60%">
      <el-form :model="desc" ref="desc" :rules="rules">
        <el-form-item prop="deleteDesc">
          <el-input type="textarea" placeholder="Устгаж буй шалтгаанаа бичнэ үү ..." v-model="desc.deleteDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Буцах</el-button>
        <el-button type="danger" @click="deletePartner('desc')" :loading="deleteLoading">Устгах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mAxios from '../../../../helper/axiosInstance'
import { getBase64 } from '../../../../helper/custom'
import draggable from 'vuedraggable'
import HistoryListDialog from './history/index.vue'
import { getUser } from '../../../../helper/auth'
import axios from 'axios'
export default {
  name: 'Information',
  async mounted () {
    this.user = await getUser()
    this.getBankCodes()
  },
  components: {
    draggable,
    HistoryListDialog
  },
  watch: {
    currentOutlet (newVal) {
      console.log(newVal, 'newVal');
      
      if (newVal) {
        this.getOutletData(newVal._id)
      } else this.outletData = null
    }
  },
  props: {
    currentOutlet: {
      type: Object,
      default: () => ({})
    },
    isShowHistoryListDialog: {
      type: Boolean
    },
    showHistoryListDialog: {
      type: Function,
      default: () => null
    },
    closeHistoryListDialog: {
      type: Function,
      default: () => null
    },
    setCurrentOutlet: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      deleteLoading: false,
      user: null,
      desc: {
        deleteDesc: ''
      },
      centerDialogVisible: false,
      outletData: null,
      loading: false,
      mainCategoryList: [],
      menuCategoryList: [],
      drawIndex: '',
      loader: false,
      load: false,
      openOptions: [
        {
          label: 'Нээлттэй',
          value: true
        },
        {
          label: 'Хаалттай',
          value: false
        }
      ],
      rules: {
        deleteDesc: [
          {
            required: true,
            message: 'Та устгаж буй шалтгаанаа заавал оруулна уу?',
            trigger: 'change'
          }
        ]
      },
      outletDataFormRules: {
        img_url: [
          {
            required: true,
            message: 'Харилцагчийн зураг оруулна уу',
            trigger: 'blur'
          }
        ],
        logo_url: [
          {
            required: true,
            message: 'Очиж авах үеийн зураг оруулна уу',
            trigger: 'blur'
          }
        ],
        full_name_mon: [
          {
            required: true,
            message: 'Харилцагчийн монгол нэрийг оруулна уу',
            trigger: 'blur'
          }
        ],
        full_name_eng: [
          {
            required: true,
            message: 'Харилцагчийн англи нэрийг оруулна уу',
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: 'Харилцагчийн монгол тайлбарыг оруулна уу',
            trigger: 'blur'
          }
        ],
        description_eng: [
          {
            required: true,
            message: 'Харилцагчийн англи тайлбарыг оруулна уу',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: 'Харилцагчийн монгол хаягыг оруулна уу',
            trigger: 'blur'
          }
        ],
        address_eng: [
          {
            required: true,
            message: 'Харилцагчийн англи хаягыг оруулна уу',
            trigger: 'blur'
          }
        ],
        service_list: [
          {
            required: true,
            message: 'Үйлчилгээ сонгоно уу',
            trigger: 'blur'
          }
        ],
        main_category_mon: [
          {
            required: true,
            message: 'Үндсэн ангилал сонгоно уу',
            trigger: 'blur'
          }
        ]
      },
      phoneListRules: {
        name: [
          {
            required: true,
            message: 'Албөн тушаал оруулна уу',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: 'Утасны дугаар оруулна уу',
            trigger: 'blur'
          }
        ]
      },
      bankCodes: [],
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: 'cooperative'
      },
      addressList: [],
      location: {
        lat: 47.918831,
        lng: 106.917558
      }
    }
  },
  methods: {
    formatStartDate(dateString) {
      if (!dateString) return null;

      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day} 00:00:00`;
    },
    changeLoc (event) {
      this.location = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      this.nearbyAddress()
    },
    async nearbyAddress () {
      await axios
        .get(
          'https://api.toktok.mn/v1/address/getAddressNearby?lat=' +
            this.location.lat +
            '&lng=' +
            this.location.lng
        )
        .then(address => {
          this.addressList = address.data.data
        })
      this.outletData.address_id = this.addressList[0]._id
    },
    async searchAddress (address) {
      await axios
        .get(
          `https://api.toktok.mn/v1/address/search?address=${address}`
        )
        .then(address => {
          this.addressList = address.data.data
        })
    },
    changeAddress (addressId) {
      this.loading = true
      this.addressList.forEach(res => {
        if (res._id === addressId) {
          this.outletData.address_id = addressId
          this.outletData.full_address = res.full_address
          this.location = {
            lat: parseFloat(res.lat),
            lng: parseFloat(res.lon)
          }
        }
      })
      this.loading = false
    },
    deletePartner (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.deleteLoading = true
          mAxios
            .post('/outlet/deleteOutletById', {
              outlet_id: this.currentOutlet._id,
              user: this.user.attributes.email,
              desc: this.desc.deleteDesc
            })
            .then((response) => {
              if (response.data.status === 'success') {
                this.$message({
                  message: 'Амжилттай устгагдлаа',
                  type: 'success'
                })
                this.deleteLoading = false
                this.centerDialogVisible = false
                this.$root.getChildOutlets()
                if (this.$root.chosenOutlets.length > 0) {
                  this.setCurrentOutlet(this.$root.chosenOutlets[0])
                }
              } else {
                this.$message({
                  message: response.data.message,
                  type: 'error'
                })
                this.centerDialogVisible = false
                this.deleteLoading = false
              }
            })
        } else {
          this.deleteLoading = false
          return false
        }
      })
    },
    getOutletData (outletId) {
      this.loading = true
      const includeFields = [
        '_id',
        'full_name_mon',
        'full_name_eng',
        'description',
        'description_eng',
        'address',
        'address_eng',
        'service_list',
        'main_category_mon',
        'menu_categories',
        'phone_list',
        'img_url',
        'email',
        'is_verified',
        'is_active',
        'is_pickup',
        'is_preOrder',
        'logo_url',
        'vat_regno',
        'vat_type',
        'bank_account_name',
        'bank_account_number',
        'bank_code',
        'commission_type',
        'percent',
        'address_id',
        'location',
        'preorder_days',
        'is_preorder_by_date',
        'preorder_start_date',
        'preorder_end_date',
        'address'
      ]
      mAxios
        .post(`/outlet/${outletId}/get`, { include_fields: includeFields })
        .then((res) => {
          if (res.data.status === 'success') {
            this.outletData = res.data.data
            this.location = {
              lat: parseFloat(this.outletData.location.split(',')[0]),
              lng: parseFloat(this.outletData.location.split(',')[1])
            }
            const phoneList = []
            res.data.data.phone_list.map((response) => {
              if (response !== null) {
                phoneList.push(response)
              }
            })
            this.outletData.phone_list = phoneList
            this.getMenuCategories()
            this.getMainCategories()
            this.nearbyAddress()
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((e) => {
          this.$message({
            message: e.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save (data) {
      const self = this
      const emptyTypes = [null, '', undefined]
      try {
        this.$refs.outletDataFormRef.validate(async (valid) => {
          if (valid) {
            if (data.is_pickup === true && emptyTypes.includes(data.logo_url)) {
              throw new Error('Лого оруулна уу!!!')
            }
            if (data.phone_list.length <= 0) {
              throw new Error(
                'Ядаж нэг хүний утасны дугаар болон албан тушаал бичих ёстой!!!'
                )
              }
              data.phone_list.map((el, index) => {
                if (
                  emptyTypes.includes(el.phone) &&
                  emptyTypes.includes(el.name)
                  ) {
                    data.phone_list.splice(index, 1)
                  }
                })
                const user = await getUser()
                data.user = user.username
                if (data.phone_list.find((el) => emptyTypes.includes(el.phone))) {
                  throw new Error('Утасны дугаар бичээгүй байна!!!')
                }
                if (data.phone_list.find((el) => emptyTypes.includes(el.name))) {
                  throw new Error('Албан тушаал бичээгүй байна!!!')
                }
                data.address_eng = data.address
                self.loading = true
                data.preorder_start_date = this.formatStartDate(this.outletData.preorder_start_date);
                data.preorder_end_date = this.formatStartDate(this.outletData.preorder_end_date);
            mAxios
              .post(`/outlet/${self.currentOutlet._id}/updateInfo`, data)
              .then((res) => {
                if (res.data.status === 'success') {
                  self.$message({
                    message: 'Амжилттай хадгаллаа',
                    type: 'success'
                  })
                  this.outletData = null
                  // self.getOutletData(data._id)
                } else {
                  self.$message.error(res.data.message)
                }
              })
              .catch((e) => {
                self.$message({
                  message: e.response.data.message,
                  type: 'error'
                })
              })
              .finally(() => {
                self.loading = false
              })
          } else {
            this.$message({
              message: 'Шаардлагатай мэдээллүүдийг бөглөнө үү',
              type: 'error'
            })
          }
        })
      } catch (error) {
        this.$message.error({
          title: 'Анхааруулга',
          message: error.message
        })
      }
    },
    onChangeServices () {
      this.getMenuCategories()
      this.getMainCategories()
    },
    checkMove: function (e) {
      this.drawIndex = 'Future index: ' + e.draggedContext.futureIndex
    },
    async getMenuCategories () {
      await mAxios
        .post('product/getMenuCategories', {
          service_list:
            this.outletData &&
            Object.prototype.hasOwnProperty.call(
              this.outletData,
              'service_list'
            )
              ? this.outletData.service_list
              : []
        })
        .then((categoriesResponse) => {
          if (categoriesResponse.data.status === 'success') {
            this.menuCategoryList = categoriesResponse.data.data
          }
        })
    },
    async getMainCategories () {
      await mAxios
        .post('product/getMainCategories', {
          service_list: this.outletData.service_list
        })
        .then((categoriesResponse) => {
          this.mainCategoryList = categoriesResponse.data.data
        })
    },
    async uploadImage (file) {
      try {
        this.load = true
        const image = new Image()
        image.src = URL.createObjectURL(file.raw)
        const promise = new Promise((resolve) => {
          image.onload = function () {
            resolve({ width: image.width, height: image.height })
          }
        })
        const result = await promise
        const isLt2M = file.size / 1024 / 1024 < 1
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.raw.type)) {
          throw new Error(
            'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
          )
        }
        if (result.width !== 600 && result.height !== 400) {
          this.load = false
          throw new Error('Зураг 600х400pxl байх ёстой.')
        }
        if (!isLt2M) throw new Error('Зураг 1MB-с бага байх ёстой.')
        getBase64(file.raw).then((res) => {
          mAxios
            .post('other/uploadImage', {
              base64: res
            })
            .then((response) => {
              if (response.data.status === 'success') {
                this.outletData.img_url = response.data.data
                this.load = false
              }
            })
        })
      } catch (error) {
        this.load = false
        this.$message.error(error.message)
      }
    },
    async uploadPickUpImage (file) {
      try {
        this.loader = true
        const image = new Image()
        image.src = URL.createObjectURL(file.raw)
        const promise = new Promise((resolve) => {
          image.onload = function () {
            resolve({ width: image.width, height: image.height })
          }
        })
        const result = await promise
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.raw.type)) {
          throw new Error(
            'Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой.'
          )
        }
        if (result.width !== 400 && result.height !== 400) {
          this.loader = false
          throw new Error('Зураг 400х400pxl байх ёстой.')
        }
        if (!isLt2M) throw new Error('Зураг 2MB-т багадаа байх ёстой.')
        getBase64(file.raw).then((res) => {
          mAxios
            .post('outlet/uploadImage', {
              base64: res
            })
            .then((response) => {
              if (response.data.data.status === 'success') {
                this.outletData.logo_url = response.data.data.data
                this.loader = false
              }
            })
        })
      } catch (error) {
        this.loader = false
        this.$message.error(error.message)
      }
    },
    categoryChange () {
      this.outletData.main_category_eng = []
      this.outletData.main_category_mon.forEach((element) => {
        this.mainCategoryList.forEach((element1) => {
          if (element === element1.name_mon) {
            this.outletData.main_category_eng.push(element1.name_eng)
          }
        })
      })
    },
    handleCloseMenuCategoryDrag (tag) {
      const index1 = this.outletData.menu_categories.findIndex((cat) => {
        return cat.menu_category_mon === tag.menu_category_mon
      })
      if (index1 > -1) {
        this.outletData.menu_categories.splice(index1, 1)
      }
    },
    pickUp (data) {
      if (data === true) {
        this.outletData.is_preOrder = true
        if (
          this.outletData.logo_url !== null ||
          this.outletData.logo_url !== ''
        ) {
          this.$message.warning({
            showClose: true,
            duration: 3000,
            message: 'Лого оруулна уу!!!'
          })
        }
      }
    },
    getBankCodes () {
      mAxios.get('getBankCodes').then((res) => {
        if (res.data.status === 'success') {
          this.bankCodes = res.data.data
        }
      })
    }
  }
}
</script>
<style scoped>
.text {
  font-size: 1.5vh;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
