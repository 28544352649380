<template>
  <div>
    <div style="padding: 10px">
      <el-steps
        :active="active"
        align-center
        finish-status="success"
        class="mt30 mb30"
      >
        <el-step title="Бүртгүүлэх" @click="active = 1"></el-step>
        <el-step title="Ерөнхий мэдээлэл" @click="active = 2"></el-step>
        <el-step title="Цагийн хуваарь" @click="active = 3"></el-step>
        <el-step title="Гэрээний материал" @click="active = 4"></el-step>
      </el-steps>
      <div class="panel">
        <div class="panel-item">
          <el-form
            :rules="rules"
            v-if="active === 0"
            :model="SignUpForm"
            ref="SignUpForm"
          >
            <el-form-item label="Нэвтрэх нэр" prop="login_name">
              <el-input
                v-model="SignUpForm.login_name"
                placeholder="Нэвтрэх нэр"
              ></el-input>
            </el-form-item>
            <el-form-item label="И-Мэйл" prop="email">
              <el-input
                v-model="SignUpForm.email"
                placeholder="И-Мэйл"
              ></el-input>
            </el-form-item>
            <el-form-item label="Нууц үг" prop="password">
              <el-input
                v-model="SignUpForm.password"
                type="password"
                placeholder="Нууц үг"
              ></el-input>
            </el-form-item>
            <el-form-item label="Нууц үг давтах" prop="repeat_password">
              <el-input
                v-model="SignUpForm.repeat_password"
                type="password"
                placeholder="Нууц үг давтах"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-button
                type="success"
                @click="next(SignUpForm)"
                style="width: 100%"
                >Дараах</el-button
              >
            </el-form-item> -->
          </el-form>
          <el-form
            :rules="rules"
            v-if="active === 1"
            :model="SignUpForm"
            ref="SignUpForm"
            v-loading.fullscreen.lock="fullscreenLoading"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Нэвтрэх нэр">
                  <el-input
                    disabled
                    v-model="SignUpForm.login_name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Үйлчилгээ" prop="service_list">
                  <el-select
                    v-model="SignUpForm.service_list"
                    filterable
                    clearable
                    multiple
                    style="width:100%"
                    placeholder="Үйлчилгээ"
                  >
                    <el-option
                      v-for="service in servicesList"
                      :label="service.name_mon"
                      :value="service.name_eng"
                      :key="service.name_eng"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Харилцагчийн нэр(Монгол)"
                  prop="supplier_name_mon"
                >
                  <el-input
                    v-model="SignUpForm.supplier_name_mon"
                    placeholder="Харилцагчийн нэр"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Харилцагчийн нэр(Англи)"
                  prop="supplier_name_eng"
                >
                  <el-input
                    v-model="SignUpForm.supplier_name_eng"
                    placeholder="Харилцагчийн нэр"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="НӨАТ-ын төрөл">
                  <el-select placeholder="Байгууллага" class="block" v-model="SignUpForm.vat_type">
                    <el-option v-for="(vat, indexVat) in vatOptions" :key="indexVat"
                    :label="vat.label"
                    :value="vat.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Байгууллагын регистр" prop="vat_regno">
                  <el-input v-model="SignUpForm.vat_regno" @change="checkRegisterFunc" placeholder="6183352 ... гэх мэт">
                    <template slot="append" v-if="registerdata !== null">{{ registerdata.data }}</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="ДАНСНЫ ДУГААР" prop="account_number">
                  <el-input placeholder="ХААН Банкны дансны дугаар" @input.native="bankNumberChange" type="number" v-model="SignUpForm.bank_account_number">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="ДАНСНЫ НЭР">
                  <el-input placeholder="Дансны нэр" v-model="SignUpForm.bank_account_name">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Хаяг" prop="address">
              <el-select
                v-model="address"
                class="block"
                filterable
                placeholder="ХУД 3-р хороо Гоёо"
                remote
                reserve-keyword
                @change="changeAddress(address)"
                value-key="_id"
                :remote-method="searchAddress"
              >
                <el-option
                  v-for="(address, indexAddress) in addressList"
                  :label="address.full_address"
                  :value="address"
                  :key="indexAddress"
                >
                </el-option>
              </el-select>
              <div class="google-map">
                <gmap-map
                  :center="center"
                  :zoom="12"
                  :options="mapOptions"
                  style="width:100%;  height: 100%; margin-top: 5px;"
                >
                  <gmap-marker
                    :position="SignUpForm.location"
                    :clickable="true"
                    @dragend="changeLoc($event)"
                    :draggable="true"
                  />
                </gmap-map>
              </div>
            </el-form-item>
            <el-form-item label="Утасны жагсаалт" prop="phone_list">
              <el-table
                :data="SignUpForm.phone_list"
              >
                <el-table-column prop="name" label="Албан тушаал">
                  <template slot-scope="scope">
                    <el-input
                      disabled
                      size="mini"
                      placeholder="Менежер"
                      v-model="scope.row.name"
                      controls-position="right"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="Утас">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      placeholder="99119911"
                      style="text-align:center"
                      type="number"
                      :min="8"
                      :max="8"
                      v-model.number="scope.row.phone"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="И-мэйл">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      placeholder="temp-email@gmail.com"
                      style="text-align:center"
                      v-model.number="scope.row.email"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
          <el-form v-if="active === 2" :rules="rules" :model="SignUpForm" ref="SignUpForm">
            <el-row type="flex" justify="center" align="middle" v-for="(day, indexDay) in time_table" :key="indexDay">
              <h3 style="width: 60px">{{ day.day }}</h3>
              <el-time-select
                class="ml10"
                @change="setTime('start', day)"
                placeholder="Нээх цаг"
                v-model="day.start_time"
                :picker-options="pickerOption"
              >
              </el-time-select>
              <el-time-select
                @change="setTime('end', day)"
                class="ml10"
                placeholder="Нээх цаг"
                v-model="day.end_time"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-row>
          </el-form>
          <el-form v-if="active === 3" :rules="rules" :model="SignUpForm" ref="SignUpForm">
            <!-- <div style="display:flex; justify-content:space-between; flex-wrap:wrap;">
              <el-form-item label="Удирдлагын овог">
                <el-input placeholder="Овог" style="width:300px;" v-model="SignUpForm.director_last_name"></el-input>
              </el-form-item>
              <el-form-item label="Удирдлагын нэр">
                <el-input placeholder="Нэр" style="width:300px;" v-model="SignUpForm.director_first_name"></el-input>
              </el-form-item>
            </div>
            <div style="display:flex; justify-content:space-between; flex-wrap:wrap;">
              <el-form-item label="Төлөөлөх ажилтны Албан тушаал">
                <el-input placeholder="Албан тушаал" style="width:300px;" v-model="SignUpForm.contract_owner_position"></el-input>
              </el-form-item>
              <el-form-item label="Төлөөлөх ажилтны нэр">
                <el-input placeholder="Нэр" style="width:300px;" v-model="SignUpForm.contract_owner"></el-input>
              </el-form-item>
            </div> -->
            <div style="display:flex; justify-content:space-between; flex-wrap:wrap;">
              <el-form-item label="Хүргэлтийн төрөл">
                <el-switch
                  v-model="SignUpForm.is_delivery_by_outlet"
                  @change="changeDeliveryType"
                  active-text="Өөрөө хүргэх"
                  inactive-text="ТокТок-р хүргүүлэх">
                </el-switch>
              </el-form-item>
              <el-form-item>
                <el-form-item label="Шимтгэлийн хувь">
                  <el-input v-model="SignUpForm.percent" v-if="SignUpForm.is_delivery_by_outlet === false">
                    <template slot="append">%</template>
                  </el-input>
                  <el-input v-else-if="SignUpForm.is_delivery_by_outlet === true && SignUpForm.supplier_type === '1'" v-model="SignUpForm.supplier_percent" class="input-with-select">
                      <el-select v-model="SignUpForm.supplier_type" slot="prepend" placeholder="Төрөл сонгох" style="width: 100px">
                      <el-option label="Хувиар" value="1"></el-option>
                      <el-option label="Төгрөг" value="2"></el-option>
                    </el-select>
                    <template slot="append">%</template>
                  </el-input>
                  <el-input v-else v-model="SignUpForm.supplier_amount" class="input-with-select">
                      <el-select v-model="SignUpForm.supplier_type" slot="prepend" placeholder="Төрөл сонгох" style="width: 100px">
                      <el-option label="Хувиар" value="1"></el-option>
                      <el-option label="Төгрөг" value="2"></el-option>
                    </el-select>
                    <template slot="append">₮</template>
                  </el-input>
                </el-form-item>
              </el-form-item>
            </div>
            <div>
              <el-form-item>
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept=".pdf"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="(file) => getFile(file)">
                  <el-row v-if="SignUpForm.files">
                    <el-tag class="avatar" type="success">
                    <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                  </el-row>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </div>
            <el-row>
              <pdf v-for="(page, index) in numPages"
                :key="index"
                :src="pdf_url"
                :page="page">
              </pdf>
            </el-row>
          </el-form>
          <el-row type="flex" justify="center" class="mt10">
            <el-button @click="generateStep('back')">Буцах</el-button>
            <el-button v-if="active === 3">
              <a style="color: black" :href="SignUpForm.is_delivery_by_outlet === true ? 'https://image.toktok.mn/client-contract/%D0%A2%D1%83%D1%81%D0%B3%D0%B0%D0%B8%CC%86%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%20-%20%D0%A5%D0%B0%D1%80%D0%B8%D0%BB%D1%86%D0%B0%D0%B3%D1%87%D0%B8%D0%B8%CC%86%D0%BD%20%D1%85%D2%AF%D1%80%D0%B3%D1%8D%D0%BB%D1%82.pdf' : 'https://image.toktok.mn/client-contract/%D0%A2%D1%83%D1%81%D0%B3%D0%B0%D0%B8%CC%86%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%20%D0%B7%D3%A9%D0%B2%20-%20Copy.pdf'" target="_blank">Хэвлэх</a>
            </el-button>
            <el-button type="success" @click="generateStep('add')">{{active === 0 ? 'Бүртгүүлэх' : active === 3 ? 'Дуусгах' : 'Үргэлжлүүлэх'}}</el-button>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mAxios from '../../../../helper/axiosInstance'
import axios from 'axios'
import { getBase64 } from '../../../../helper/custom'
import pdf from 'vue-pdf'
export default {
  name: 'SignUp',
  components: {
    pdf
  },
  created () {
    this.getServices()
    this.getBankCodes()
    this.pdf_url = pdf.createLoadingTask('https://d2ib3wb1gajb95.cloudfront.net/client_special_contract.pdf')
    this.pdf_url.promise.then(pdf => {
      this.numPages = pdf.numPages
    })
    // if (this.$root.isAdmin ? this.$root.chosenOutlets[0].vat_regno !== '' : this.$root.outlet.vat_regno !== '') {
    //   this.checkRegisterFunc(this.$root.isAdmin ? this.$root.chosenOutlets[0].vat_regno : this.$root.outlet.vat_regno)
    // }
    this.vat_regno = this.$root.isAdmin ? this.$root.chosenOutlets[0].vat_regno !== '' ? this.$root.chosenOutlets[0].vat_regno : '' : this.$root.outlet.vat_regno !== '' ? this.$root.outlet.vat_regno : ''
    this.bank_account_name = this.$root.isAdmin ? this.$root.chosenOutlets[0].bank_account_name !== '' ? this.$root.chosenOutlets[0].bank_account_name : '' : this.$root.outlet.bank_account_name !== '' ? this.$root.outlet.bank_account_name : ''
    this.bank_account_number = this.$root.isAdmin ? ![null, 0, '', undefined].includes(this.$root.chosenOutlets[0].bank_account_number) ? this.$root.chosenOutlets[0].bank_account_number : ''
      : ![null, 0, '', undefined].includes(this.$root.outlet.bank_account_number)
        ? this.$root.outlet.bank_account_number
        : ''
  },
  watch: {
    address (newValue) {
      this.SignUpForm.location.lat = newValue.lat
      this.SignUpForm.location.lng = newValue.lon
    }
  },
  props: {
    setCurrentOutlet: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      pickerOption: {
        start: '00:05',
        step: '00:05',
        end: '23:55'
      },
      pdf_url: '',
      numPages: 0,
      signUpData: null,
      bankCodes: [],
      registerdata: null,
      addressList: [],
      address: null,
      SignUpForm: {
        is_delivery_by_outlet: false,
        percent: '20',
        supplier_percent: '5',
        supplier_amount: '3000',
        supplier_type: '1',
        supplier_name_mon: '',
        supplier_name_eng: '',
        service_list: [],
        vat_regno: '',
        vat_type: 3,
        bank: 'ХААН БАНК',
        bank_account_name: '',
        bank_account_number: '',
        // director_last_name: '',
        // director_first_name: '',
        // contract_owner: '',
        // contract_owner_position: '',
        files: '',
        type: '',
        contentType: '',
        phone_list: [
          {
            phone: '',
            name: 'Захиалга',
            email: ''
          },
          {
            phone: '',
            name: 'Менежер',
            email: ''
          },
          {
            phone: '',
            name: 'Санхүү',
            email: ''
          },
          {
            phone: '',
            name: 'Захирал',
            email: ''
          }
        ],
        email: '',
        password: '',
        repeat_password: '',
        location: {
          lat: 47.918831,
          lng: 106.917558
        },
        login_name: ''
      },
      username: '',
      center: {
        lat: 47.918831,
        lng: 106.917558
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: 'greedy'
      },
      servicesList: [],
      active: 0,
      rules: {
        supplier_name_mon: [
          {
            required: true,
            message: 'Харилцагчийн Монгол нэр оруулна уу',
            trigger: ['blur', 'change']
          }
        ],
        supplier_name_eng: [
          {
            required: true,
            message: 'Харилцагчийн Англи нэр оруулна уу',
            trigger: ['blur', 'change']
          }
        ],
        service_list: [
          {
            required: true,
            message: 'Үйлчилгээний төрөл сонгоогүй байна.',
            trigger: ['blur', 'change']
          }
        ],
        email: [
          {
            required: true,
            message: 'Мэйл хаяг оруулна уу',
            trigger: ['blur', 'change']
          },
          {
            type: 'email',
            message: 'Шаардлага хангахгүй мэйл хаяг байна',
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: 'Нууц үгээ оруулна уу',
            trigger: ['blur', 'change']
          }
        ],
        repeat_password: [
          {
            required: true,
            message: 'Нууц үгээ давтан оруулна уу',
            trigger: ['blur', 'change']
          }
        ],
        login_name: [
          {
            required: true,
            message: 'Нэвтрэх нэр оруулна уу',
            trigger: ['blur', 'change']
          }
        ],
        vat_regno: [
          {
            required: true,
            message: 'Регистрийн дугаар оруулна уу',
            trigger: ['blur', 'change']
          }
        ]
      },
      time_table: [
        {
          day: 'Даваа',
          start_time: '',
          end_time: ''
        },
        {
          day: 'Мягмар',
          start_time: '',
          end_time: ''
        },
        {
          day: 'Лхагва',
          start_time: '',
          end_time: ''
        },
        {
          day: 'Пүрэв',
          start_time: '',
          end_time: ''
        },
        {
          day: 'Баасан',
          start_time: '',
          end_time: ''
        },
        {
          day: 'Бямба',
          start_time: '',
          end_time: ''
        },
        {
          day: 'Ням',
          start_time: '',
          end_time: ''
        }
      ],
      type: 'verify',
      delivery: [
        {
          label: 'Ток ток-оор хүргүүлэх',
          value: false
        },
        {
          label: 'Харилцагч өөрөө хүргэх',
          value: false
        }
      ],
      vatOptions: [
        {
          value: 3,
          label: 'Байгууллага'
        },
        {
          value: 1,
          label: 'Хувь хүн'
        }
      ],
      fullscreenLoading: false
    }
  },
  methods: {
    changeDeliveryType (type) {
      if (type === true) {
        this.pdf_url = pdf.createLoadingTask('https://d2ib3wb1gajb95.cloudfront.net/client_delivery_contract.pdf')
      } else {
        this.pdf_url = pdf.createLoadingTask('https://d2ib3wb1gajb95.cloudfront.net/client_special_contract.pdf')
      }
      this.pdf_url.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    },
    async getFile (file) {
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        this.$message.error('Хуулах файлын хэмжээ 1MB-с хэтрэхгүй байх ёстой!')
      }
      if (isLt2M) {
        await getBase64(file.raw).then((response) => {
          const tempData = file.raw.type.split('/')
          this.SignUpForm.files = response
          this.SignUpForm.type = tempData[1]
          this.SignUpForm.contentType = tempData[0]
        })
      }
    },
    getBankCodes () {
      mAxios.get('getBankCodes').then((res) => {
        if (res.data.status === 'success') {
          this.bankCodes = res.data.data
        }
      })
    },
    async bankNumberChange (event) {
      if (event.target.value.length === 10) {
        this.getBankAccountName(event.target.value)
      }
    },
    async getBankAccountName (bankAccountNumber) {
      const body = {
        brand_name: 'TokTok',
        account_number: bankAccountNumber
      }
      const res = await axios.post(
        'https://rest.toktok.mn/payment/corporate/accountName',
        body
      )
      if (res.data.status === 'success') {
        this.SignUpForm.bank_account_name = res.data.response.custFirstName
          ? res.data.response.custFirstName
          : res.data.response.custLastName
      }
      this.$forceUpdate()
    },
    async checkRegisterFunc (register) {
      this.fullscreenLoading = true
      await mAxios.get('ebarimt/checkEbarimt?reg_no=' + register).then((response) => {
        if (
          response.data.status === 'success' &&
          response.data.data.error === null
        ) {
          this.registerdata = response.data.data
        }
        this.fullscreenLoading = false
      })
    },
    async nearbyAddress (lat, lng) {
      await axios
        .get(
          'https://api.toktok.mn/v1/address/getAddressNearby?lat=' +
            lat +
            '&lng=' +
            lng
        )
        .then((address) => {
          this.address = address.data.data[0]
          this.SignUpForm.location.lat = address.data.data[0].lat
          this.SignUpForm.location.lng = address.data.data[0].lon
          this.addressList = [address.data.data[0]]
        })
    },
    async searchAddress (address) {
      await axios
        .get(
          `https://api.toktok.mn/v1/address/search?address=${address}`
        )
        .then((address) => {
          this.addressList = address.data.data
        })
    },
    async generateTimeTable () {
      const tempData = {
        mon: {
          startTime: '',
          endTime: ''
        },
        tue: {
          startTime: '',
          endTime: ''
        },
        wed: {
          startTime: '',
          endTime: ''
        },
        thu: {
          startTime: '',
          endTime: ''
        },
        fri: {
          startTime: '',
          endTime: ''
        },
        sun: {
          startTime: '',
          endTime: ''
        },
        sat: {
          startTime: '',
          endTime: ''
        }
      }
      this.time_table.forEach((day) => {
        if (day.day === 'Даваа') {
          tempData.mon.startTime = day.start_time
          tempData.mon.endTime = day.end_time
        } else if (day.day === 'Мягмар') {
          tempData.tue.startTime = day.start_time
          tempData.tue.endTime = day.end_time
        } else if (day.day === 'Лхагва') {
          tempData.wed.startTime = day.start_time
          tempData.wed.endTime = day.end_time
        } else if (day.day === 'Пүрэв') {
          tempData.thu.startTime = day.start_time
          tempData.thu.endTime = day.end_time
        } else if (day.day === 'Баасан') {
          tempData.fri.startTime = day.start_time
          tempData.fri.endTime = day.end_time
        } else if (day.day === 'Бямба') {
          tempData.sat.startTime = day.start_time
          tempData.sat.endTime = day.end_time
        } else {
          tempData.sun.startTime = day.start_time
          tempData.sun.endTime = day.end_time
        }
      })
      return tempData
    },
    setTime (type, day) {
      if (type === 'start' && day.day === 'Даваа') {
        this.time_table.forEach((el) => {
          el.start_time = day.start_time
        })
      } else if (type === 'end' && day.day === 'Даваа') {
        this.time_table.forEach((el) => {
          el.end_time = day.end_time
        })
      }
    },
    generateStep (type) {
      this.$refs.SignUpForm.validate(async (valid) => {
        if (valid) {
          const emptyTypes = [null, '', undefined]
          if (type === 'add' && this.active === 0) {
            this.next(this.SignUpForm)
          } else if (this.active === 1) {
            if (['', null, undefined].includes(this.address)) {
              this.$notify.error({
                title: 'Error',
                message:
                'Та хаягаа оруулна уу!!!'
              })
              return false
            }
            if (['', null, undefined].includes(this.registerdata)) {
              this.$notify.error({
                title: 'Error',
                message: 'Та регистрийн дугаар зөв оруулна уу!!!'
              })
              return false
            }
            if (this.SignUpForm.phone_list.length <= 0) {
              return this.$notify.error({
                title: 'Error',
                message:
                'Ядаж нэг хүний утасны дугаар болон албан тушаал бичих ёстой!!!'
              })
            }
            this.SignUpForm.phone_list.map((el, index) => {
              if (
                emptyTypes.includes(el.phone) &&
                emptyTypes.includes(el.name)
              ) {
                this.SignUpForm.phone_list.splice(index, 1)
              }
            })
            if (this.SignUpForm.phone_list.find((el) => emptyTypes.includes(el.email))) {
              return this.$notify.error({
                title: 'Error',
                message: 'Мэйл хаяг бичээгүй байна!!!'
              })
            }
            if (this.SignUpForm.phone_list.find((el) => emptyTypes.includes(el.phone))) {
              return this.$notify.error({
                title: 'Error',
                message: 'Утасны дугаар бичээгүй байна!!!'
              })
            } else {
              this.active++
            }
          } else if (type === 'add' && this.active === 2) {
            if (this.time_table.find((el) => emptyTypes.includes(el.start_time))) {
              return this.$notify.error({
                title: 'Error',
                message: 'Нээх цаг бөглөөгүй байна!!!'
              })
            }
            if (this.time_table.find((el) => emptyTypes.includes(el.end_time))) {
              return this.$notify.error({
                title: 'Error',
                message: 'Хаах цаг бөглөөгүй байна!!!'
              })
            } else {
              this.active++
            }
          } else if (type === 'add' && this.active === 3) {
            this.register()
          } else if (type === 'add') {
            if (this.active < 3) {
              this.active++
            }
          } else {
            if (this.active > 0) {
              this.active--
            }
          }
        } else {
          return this.$notify.warning({
            title: 'Анхааруулга',
            message: 'Зарим талбар бөглөөгүй байна!!!'
          })
        }
      })
    },
    addPhoneRow () {
      this.SignUpForm.phone_list.push({ name: '', phone: '' })
    },
    deletePhoneRow (index) {
      this.SignUpForm.phone_list.splice(index, 1)
    },
    async generatePayload () {
      const payload = {
        parent_id: this.$root.chosenOutlets[0]._id,
        is_child: true,
        cognito_id: this.signUpData.Attributes[0].Value,
        email: this.SignUpForm.email,
        full_name_eng: this.SignUpForm.supplier_name_eng,
        service_list: this.SignUpForm.service_list,
        username: this.SignUpForm.login_name,
        time_table: await this.generateTimeTable(),
        phone_list: this.SignUpForm.phone_list,
        address: this.address.full_address,
        address_id: this.address._id,
        location:
          this.SignUpForm.location.lat + ', ' + this.SignUpForm.location.lng,
        vat_regno: this.SignUpForm.vat_regno,
        vat_type: this.SignUpForm.vat_type,
        company_name: this.registerdata.data,
        bank_account_name: this.SignUpForm.bank_account_name,
        bank_account_number: this.SignUpForm.bank_account_number,
        is_delivery_by_outlet: this.SignUpForm.is_delivery_by_outlet,
        commission_type:
          this.SignUpForm.is_delivery_by_outlet &&
          this.SignUpForm.supplier_type === '1'
            ? 'percent'
            : this.SignUpForm.is_delivery_by_outlet === false
              ? 'percent'
              : 'fixed',
        percent:
          this.SignUpForm.is_delivery_by_outlet === true &&
          this.SignUpForm.supplier_type === '1'
            ? this.SignUpForm.supplier_percent
            : this.SignUpForm.supplier_amount,
        // contract_owner_position: this.SignUpForm.contract_owner_position,
        // contract_owner: this.SignUpForm.contract_owner,
        // director_first_name: this.SignUpForm.director_first_name,
        // director_last_name: this.SignUpForm.director_last_name,
        file: this.SignUpForm.files,
        type: this.SignUpForm.type,
        contentType: this.SignUpForm.contentType
      }
      return payload
    },
    next (data) {
      this.$refs.SignUpForm.validate(async (valid) => {
        if (valid) {
          if (data.password === data.repeat_password) {
            const payload = {
              username: data.login_name,
              email: data.email,
              password: data.password
            }
            await mAxios
              .post('outlet/addChildOutletToCognito', payload)
              .then((response) => {
                if (response.data.status === 'success') {
                  if (response.data.data.status === 'success') {
                    this.signUpData = response.data.data.data.User
                    this.$message.success({
                      title: 'Амжилттай',
                      message: response.data.data.message,
                      type: 'success'
                    })
                    this.active++
                  } else {
                    this.$message.error({
                      title: 'Алдаа',
                      message: response.data.data.message,
                      type: 'error'
                    })
                  }
                } else {
                  if (response.data.message === 'Password does not conform to policy: Password must have symbol characters') {
                    this.$message.error({
                      title: 'Алдаа',
                      message: 'Та тэмдэгт оруулж өгнө үү!',
                      type: 'error'
                    })
                  } else if (response.data.message === 'Password does not conform to policy: Password must have uppercase characters') {
                    this.$message.error({
                      title: 'Алдаа',
                      message: 'Та том үсэг оруулж өгнө үү!',
                      type: 'error'
                    })
                  } else {
                    this.$message.error({
                      title: 'Алдаа',
                      message: response.data.message,
                      type: 'error'
                    })
                  }
                }
              })
          } else {
            this.$message.warning({
              title: 'Анхааруулга',
              message: 'Нууц үг тохирсонгүй!!!',
              type: 'warning'
            })
            return false
          }
        } else {
          this.$message.warning({
            title: 'Анхааруулга',
            message: 'Зарим талбарыг бөглөөгүй байна.',
            type: 'warning'
          })
          return false
        }
      })
    },
    changeLoc (event) {
      this.SignUpForm.location.lat = event.latLng.lat()
      this.SignUpForm.location.lng = event.latLng.lng()
      this.nearbyAddress(event.latLng.lat(), event.latLng.lng())
    },
    changeAddress (address) {
      this.address = address
      this.SignUpForm.location.lat = address.lat
      this.SignUpForm.location.lng = address.lon
      this.addressList = [address]
    },
    getServices () {
      mAxios.get('getServices').then((servicesResponse) => {
        if (servicesResponse.data.status === 'success') {
          const tempData = []
          if (servicesResponse.data.data.length > 0) {
            servicesResponse.data.data.forEach((element) => {
              if (
                element.name_eng !== 'discounted food' &&
                element.name_eng !== 'delivery'
              ) {
                tempData.push(element)
              }
            })
          }
          this.servicesList = tempData
        }
      })
    },
    async register () {
      this.$refs.SignUpForm.validate(async (valid) => {
        if (valid) {
          const payload = await this.generatePayload()
          await mAxios
            .post('outlet/registerChildOutlet', payload)
            .then((response) => {
              if (response.data.status === 'success') {
                this.$root.getChildOutlets()
                if (this.$root.chosenOutlets.length > 0) {
                  this.setCurrentOutlet(this.$root.chosenOutlets[0])
                }
                this.$message.success({
                  title: 'Амжилттай',
                  message: response.data.data.message,
                  type: 'success'
                })
              } else {
                this.$message.error({
                  title: 'Алдаа гарлаа',
                  message: response.data.message,
                  type: 'error'
                })
              }
            })
        } else {
          this.$message.warning({
            title: 'Анхааруулга',
            message: 'Зарим талбарыг бөглөөгүй байна.',
            type: 'warning'
          })
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.el-tag.el-tag--success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.avatar-uploader .el-upload {
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: orange;
}
.avatar-uploader-icon {
  border: 1px solid #e8e8e8;
  border-radius: 30px;
  font-size: 28px;
  color: gray;
  width: 378px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 378px;
  height: 178px;
  border-radius: 30px;
  display: block;
}
</style>
