<template>
  <div>
    <el-drawer
      :title="'Дэлгэрэнгүй'"
      :visible="isShow"
      @open="onOpen"
      @close="onClose"
      size="90%"
      style="align-items: center; height: 100%"
    >
      <el-row :gutter="20" v-if="logDetailData" class="modalMain">
        <el-col :span="12" class="modal">
          <h3>
            Хуучин
          </h3>
          <DetailComponent
            :data="logDetailData"
            type="old"
          />
        </el-col>
        <el-col :span="12" class="modal">
          <h3>
            Шинэ
          </h3>
          <DetailComponent
            :data="logDetailData"
            type="new"
          />
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import mAxios from '../../../../../helper/axiosInstance'
import DetailComponent from './detailComponent.vue'
export default {
  name: 'OutletHistoryDetail',
  mounted () {},
  components: {
    DetailComponent
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    historyId: {
      type: String,
      default: () => null
    },
    outletId: {
      type: String,
      default: () => null
    },
    closeFunction: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      logDetailData: null
    }
  },
  methods: {
    getHistoryDetail () {
      mAxios
        .post('/outlet/infoHistoryDetail', {
          history_id: this.historyId,
          outlet_id: this.outletId
        })
        .then((res) => {
          if (res.data.status === 'success') {
            this.logDetailData = res.data.data
          }
        })
    },
    onOpen () {
      this.getHistoryDetail()
    },
    onClose () {
      this.closeFunction()
    }
  }
}
</script>

<style>
</style>
