<template>
  <div>
    <el-drawer
      :title="'Харилцагчийн түүх'"
      :visible="isShow"
      :size="size"
      style="align-items: center; height: 100%"
      @close="mCloseDialog()"
      @open="mOpenDialog()"
    >
      <el-table :data="historyList">
        <el-table-column
          type="index"
          label="№"
          width="50"
        ></el-table-column>
        <el-table-column prop="created_at" label="Огноо"></el-table-column>
        <el-table-column prop="action" label="Үйлдэл"></el-table-column>
        <el-table-column
          prop="desc"
          label="Тайлбар"
        ></el-table-column>
        <el-table-column prop="user" label="Хэрэглэгч"></el-table-column>
        <el-table-column label="Дэлгэрэнгүй">
          <template slot-scope="prop">
            <el-button
              type="primary"
              size="mini"
              @click="getHistoryDetail(prop.row)"
              >Дэлгэрэнгүй</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="text-right mt10"
        background
        layout="total, prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="getHistoryList"
      >
      </el-pagination>
    </el-drawer>
    <DetailDialog
      :isShow="isDetailShow"
      :historyId="historyId"
      :outletId="outletId"
      :closeFunction="mCloseDetailDialog"
    />
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
import DetailDialog from './detail.vue'
export default {
  name: 'OutletHistoryList',
  components: {
    DetailDialog
  },
  props: {
    isShow: {
      type: Boolean,
      default: () => false
    },
    outletId: {
      type: String,
      default: () => null
    },
    closeFunction: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      historyList: [],
      total: 0,
      page: 1,
      pageSize: 10,
      isDetailShow: false,
      historyId: null,
      size: ''
    }
  },
  mounted () {
    if (window.innerWidth < 600) {
      this.size = '95%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '65%'
    } else {
      this.size = '50%'
    }
  },
  methods: {
    mCloseDialog () {
      this.isDetailShow = false
      this.closeFunction()
    },
    mOpenDialog () {
      this.getHistoryList(1)
    },
    getHistoryList (page) {
      this.page = page
      mAxios
        .post('/outlet/infoHistory', {
          outlet_id: this.outletId,
          page: page
        })
        .then((res) => {
          if (res.data.status === 'success') {
            this.historyList = res.data.data.hits
            this.page = res.data.current_page
            this.total = res.data.total
            this.pageSize = res.data.page_size
          }
        })
    },
    mCloseDetailDialog () {
      this.isDetailShow = false
    },
    getHistoryDetail (data) {
      this.historyId = data._id
      this.isDetailShow = true
    }
  }
}
</script>
