<template>
  <div class="clientInformation"  v-loading="loading">
    <header class="page-header">
      <el-row type="flex" align="middle" class="headerLine">
        <title-item title="Харилцагчийн мэдээлэл" />
          <span class="ml10 infoList">(Нийт <el-tag type="info"><strong>{{$root.chosenOutlets.length}}</strong></el-tag> салбар байна.)</span>
           <button class="btn" type="button" @click="showHistoryListDialog">
            <i class="el-icon-document"></i>
            <span>Түүх</span>
           </button>
      </el-row>
    </header>
    <el-select class="select" v-model="value" placeholder="Select" @change="selectVal">
      <el-option
      v-for="(item, index) in $root.chosenOutlets"
      :key="index"
      :label="item.full_name_eng"
      :value="item._id"
      />
    </el-select>
    <button type="button" @click="addPartner" class="addPartner mt20">
      <i class="el-icon-plus mr10"></i>
      <span  style="font-size: 14px; font-family:sans-serif; color: #777777;">Салбар нэмэх</span>
    </button>
    <div class="dashLine mt20 mb20"></div>
    <div class="panel" style="padding-bottom: 0; border-radius: 0;">
      <el-row :gutter="0" type="flex">
        <el-col :span="6" class="border-right infoList">
          <ul class="list-style-none mt0 mb0 p0 menu-list">
            <li
              v-for="(mOutlet, outletIndex) in $root.chosenOutlets"
              :key="outletIndex"
              :class="(currentOutlet && currentOutlet._id && mOutlet._id === currentOutlet._id )? 'active' : ''"
              @click="setCurrentOutlet(mOutlet)"
            >
              <el-row>
                <el-col :span="20">
                <span class="mr10">{{outletIndex + 1}}.</span>
                  {{ mOutlet.full_name_eng }}
                </el-col>
                <el-col :span="4">
                  <el-switch v-model="mOutlet.is_active" @change="changeOutlet(mOutlet)"></el-switch>
                </el-col>
              </el-row>
            </li>
            <li class="border-top text-success" @click="addPartner"><i class="el-icon-plus mr10"></i>Салбар нэмэх </li>
            <li class="text-success" @click="dialogVisible = true"><i class="el-icon-document-copy mr10"></i>Салбар хуулах </li>
          </ul>
        </el-col>
        <el-col :span="18" class="infoDetail">
          <add-partner v-if="isAddPartner" :setCurrentOutlet="setCurrentOutlet"></add-partner>
          <information v-else :currentOutlet="currentOutlet" :setCurrentOutlet="setCurrentOutlet" :showHistoryListDialog="showHistoryListDialog" :isShowHistoryListDialog="isShowHistoryListDialog" :closeHistoryListDialog="closeHistoryListDialog"/>
        </el-col>
      </el-row>
    </div>
    <el-drawer
      title="Харилцагч хуулах"
      :visible.sync="dialogVisible"
      :with-header="true">
      <el-form :model="copyData" :rules="rules" ref="copyData" class="p20">
        <el-row type="flex" justify="space-between" :gutter="20">
          <el-col :span="12">
            <el-form-item label="Салбарын монгол нэр" prop="full_name_mon">
              <el-input placeholder="Монгол нэр оруулах" v-model="copyData.full_name_mon"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Салбарын англи нэр" prop="full_name_eng">
              <el-input placeholder="Анли нэр оруулах" v-model="copyData.full_name_eng"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="mt20" justify="end">
          <el-button @click="dialogVisible = false">Болих</el-button>
          <el-button type="primary" @click="copyPartner('copyData')">Хуулах</el-button>
        </el-row>
      </el-form>
    </el-drawer>
    <el-drawer
      :title="isActive ? 'Салбар нээх' : 'Салбар хаах'"
      :visible.sync="isOpenDailog"
      :with-header="true"
      :before-close="handleClick">
      <el-form :model="isActiveData" :rules="rulesActiveData" ref="isActiveData" class="p20">
        <el-row type="flex" :gutter="20" v-if="currentOutlet && currentOutlet.is_active === false">
          <el-form-item label="Нээх хугацаа оруулах" prop="open_date">
            <el-date-picker
              v-model="isActiveData.open_date"
              type="datetime"
              placeholder="Хугацаа сонгох"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="Шалтгаан сонгох" prop="desc">
            <el-radio-group v-model="isActiveData.desc">
              <el-radio label="Дотоод ажилтай" :disabled="this.isActive"></el-radio>
              <el-radio label="Засвартай" :disabled="this.isActive"></el-radio>
              <el-radio label="Олон хоногоор амарна" :disabled="this.isActive"></el-radio>
              <el-radio label="Бусад"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row type="flex" v-if="isActiveData.desc === 'Бусад'">
          <el-form-item label="" style="width: 100%">
           <el-input type="textarea" :rows="2" v-model="isActiveData.reason" placeholder="Шалтгаан оруулах ..."></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex" class="mt20" justify="end">
          <el-button @click="handleClick">Буцах</el-button>
          <!-- <el-button type="success" v-if="isActive" @click="updateStatus('isActiveData')">Нээх</el-button> -->
          <el-button type="danger" @click="updateStatus('isActiveData')">Хаах</el-button>
        </el-row>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import information from './components/information.vue'
import addPartner from './components/addPartner.vue'
import TitleItem from '../../../components/items/titleItem.vue'
import mAxios from '../../../helper/axiosInstance'
export default {
  components: { information, addPartner, TitleItem },
  name: 'Info',
  watch: {
    '$root.chosenOutlets' (newVal, oldVal) {
      if (newVal.length > 0 && this.currentOutlet) {
        if (!newVal.find((el) => el._id === this.currentOutlet._id)) {
          this.currentOutlet = null
        }
      }
    }
  },
  data () {
    return {
      value: this.$root.chosenOutlets[0].full_name_eng,
      isOpenOutletId: '',
      isOpenDailog: false,
      currentOutlet: null,
      outlets: [],
      loading: false,
      isAddPartner: false,
      isShowHistoryListDialog: false,
      bankCodes: [],
      dialogVisible: false,
      isActive: false,
      isActiveData: {
        is_active: false,
        reason: '',
        desc: 'Дотоод ажилтай',
        open_date: ''
      },
      copyData: {
        full_name_eng: '',
        full_name_mon: ''
      },
      rules: {
        full_name_eng: [
          { required: true, message: 'Та англи нэр оруулна уу!', trigger: 'blur' }
        ],
        full_name_mon: [
          { required: true, message: 'Та монгол нэр оруулна уу!', trigger: 'blur' }
        ]
      },
      rulesActiveData: {
        open_date: [
          { validator: true, message: 'Та хугацаа оруулна уу!', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Та шалтгаан оруулна уу!', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 || time.getTime() > Date.now() + 3600 * 1000 * 24 * 30
        }
      }
    }
  },
  mounted () {
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'id') && this.$root.chosenOutlets.length > 0) {
      this.$root.chosenOutlets.forEach(element => {
        if (element._id === this.$route.params.id) {
          this.setCurrentOutlet(element)
        }
      })
    } else if (this.$root.chosenOutlets.length > 0) {
      this.setCurrentOutlet(this.$root.chosenOutlets[0])
    }
  },
  methods: {
    handleClick () {
      this.isOpenDailog = false
      this.isActiveData = {
        is_active: false,
        desc: this.isActive === true ? 'Бусад' : 'Дотоод ажилтай',
        open_date: ''
      }
      this.$root.getChildOutlets()
    },
    changeOutlet (outlet) {
      this.isActiveData.desc = outlet.is_active === true ? 'Бусад' : 'Дотоод ажилтай'
      this.isActive = outlet.is_active
      this.isOpenOutletId = outlet._id
      if (outlet.is_active === true) {
        this.isOpenDailog = false
        this.updateStatus('isActiveData')
      } else {
        this.isOpenDailog = true
      }
    },

    updateStatus (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let body = {}
          if (this.isActive === false) {
            body = {
              is_active: this.isActive,
              desc: this.isActiveData.desc === 'Бусад' ? this.isActiveData.reason : this.isActiveData.desc,
              open_date: this.isActiveData.open_date
            }
          } else {
            body = {
              is_active: this.isActive,
              desc: this.isActiveData.reason
            }
          }
          this.loading = true
          mAxios
            .post(`/outlet/${this.isOpenOutletId}/updateStatus`, body)
            .then((response) => {
              if (response.data.status === 'success') {
                if (!this.$root.isAdmin) {
                  this.$root.getChildOutlets()
                }
                this.$message({
                  message: this.isActive === true ? 'Таны салбар амжилттай нээгдлээ.' : `Таны салбар ${this.isActiveData.open_date} хүртэл хаагдлаа`,
                  type: 'success'
                })
                this.isActiveData = {
                  is_active: false,
                  desc: 'Дотоод ажилтай',
                  open_date: ''
                }
                this.isOpenDailog = false
              } else {
                this.isOpenDailog = false
                this.$message.error('Хүсэлт ажжилтгүй боллоо')
              }
            }).catch((e) => {
              this.$message({
                message: 'Хүсэлт ажжилтгүй боллоо',
                type: 'error'
              })
              this.isOpenDailog = false
            })
            .finally(() => {
              this.isOpenDailog = false
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    copyPartner (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const body = {
            outletId: this.$root.isAdmin ? this.$root.chosenOutlets[0]._id : this.$root.outlet._id,
            full_name_eng: this.copyData.full_name_eng,
            full_name_mon: this.copyData.full_name_mon
          }
          this.loading = true
          mAxios
            .post('/outlet/copyPartner', body)
            .then((response) => {
              if (response.data.status === 'success') {
                this.$root.getChildOutlets()
                this.$message({
                  message: response.data.data.message,
                  type: 'success'
                })
                this.dialogVisible = false
              } else {
                this.dialogVisible = false
                this.$message.error(response.data.message)
              }
            }).catch((e) => {
              this.$message({
                message: e.response.data.message,
                type: 'error'
              })
              this.dialogVisible = false
            })
            .finally(() => {
              this.dialogVisible = false
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    showHistoryListDialog () {
      this.isShowHistoryListDialog = true
    },
    closeHistoryListDialog () {
      this.isShowHistoryListDialog = false
    },
    selectVal (item) {
      this.currentOutlet = null
      setTimeout(() => {
        this.currentOutlet = this.$root.chosenOutlets.find(
          (el) => el._id === item
        )
      }, 300)
      this.isAddPartner = false
      this.$forceUpdate()
    },
    async setCurrentOutlet (outlet) {
      this.currentOutlet = null
      setTimeout(() => {
        this.currentOutlet = outlet
      }, 300)
      this.isAddPartner = false
      this.$forceUpdate()
    },
    addPartner () {
      this.isAddPartner = true
    }
  }
}
</script>
<style>
.hideUpload > div {
  display: none;
}
.diffHistory {
  /* background-color: #E4EBE4;  */
  color: rgb(135, 206, 135);
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.5s ease-in forwards;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}
</style>
