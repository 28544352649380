<template>
  <el-card>
    <el-form
      v-if="data[type]"
    >
      <div class="panel-item">
        <el-row>
          <el-col :span="12" class="text-left">
            <el-form-item v-if="data[type]">
              <el-col :span="10">
                <div class="image-upload-container">
                  <div class="image-uploader invoice-image">
                    <div class="image-placeholder">
                      <div class="image-container">
                        <div class="overlay">
                          <el-upload
                            action=""
                            disabled
                            :show-file-list="false"
                            :auto-upload="false"
                          >
                            <img
                              v-if="data[type].img_url"
                              :src="data[type].img_url"
                              class="avatar"
                              style="width:100%;"
                            />
                            <span
                              ><i
                                v-if="data[type].img_url"
                                slot="default"
                                class="el-icon-plus"
                                >Зураг солих</i
                              >
                              <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                                style="width:300px; height:200px;"
                                >Зураг оруулах</i
                              ></span
                            >
                          </el-upload>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div>
        <div class="ml20">
          <header>Ерөнхий мэдээлэл</header>
        </div>
        <el-row :gutter="40">
          <el-col :span="40">
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('is_verified')">
                <el-form-item>
                  <el-checkbox v-model="data[type].is_verified" disabled>
                    Гэрээ байгуулсан эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12" :class="checkDiff('is_active')">
                <el-form-item >
                  <el-checkbox v-model="data[type].is_active" disabled>
                    Нээлттэй эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('is_pickup')">
                <el-form-item>
                  <el-checkbox
                    v-model="data[type].is_pickup"
                    disabled
                  >
                    Очиж авах
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12" :class="checkDiff('is_preOrder')">
                <el-form-item >
                  <el-checkbox
                    v-model="data[type].is_preOrder"
                    disabled
                    class="aa"
                  >
                    Урьдчилсан захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('service_list')">
                <el-form-item size="small" label="Үйлчилгээнүүд">
                  <el-select
                    v-model="data[type].service_list"
                    placeholder="Select"
                    disabled
                    class="dblock"
                    clearable
                    filterable
                    multiple
                  >
                    <el-option
                      v-for="service in $root.servicesList"
                      :label="service.name_mon"
                      :value="service.name_eng"
                      :key="service.name_eng"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :class="checkDiff('main_categories')">
                <el-form-item
                  label="Үндсэн ангилал"
                  size="small"
                  prop="main_category_mon"
                >
                  <el-select
                    v-model="data[type].main_category_mon"
                    multiple
                    disabled
                    filterable
                    placeholder="Select"
                    class="dblock"
                  >
                    <el-option
                      v-for="item in data[type].main_categories"
                      :key="item"
                      :label="item.name_mon"
                      :value="item.name_mon"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('menu_categories')">
                <el-form-item
                  label="Дотоод ангилал"
                  size="small"
                  prop="menu_categories"
                >
                  <el-select
                    v-model="data[type].menu_categories"
                    multiple
                    value-key="menu_category_mon"
                    filterable
                    disabled
                    collapse-tags
                    placeholder="хоол сонгох"
                  >
                    <el-option
                      v-for="(item, index) in data[type].menu_categories"
                      :key="index"
                      :label="item.menu_category_mon"
                      :value="item"
                    >
                      <span style="float: left">{{
                        item.menu_category_mon
                      }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.service }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
            </el-row>
          </div>
            <div class="panel-item">
              <el-row :gutter="20" class="mb30">
                <el-col :span="12" :class="checkDiff('full_name_mon')">
                  <el-form-item prop="full_name_mon" label="Нэр монгол">
                    <el-input v-model="data[type].full_name_mon" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :class="checkDiff('full_name_eng')">
                  <el-form-item prop="full_name_eng" label="Нэр англи">
                    <el-input v-model="data[type].full_name_eng" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mb30">
                <el-col :span="12" :class="checkDiff('description')">
                  <el-form-item
                    prop="description"
                    label="Рестораны танилцуулга(Монгол)"
                  >
                    <el-input
                      v-model="data[type].description"
                      type="textarea"
                      :rows="2"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :class="checkDiff('description_eng')">
                  <el-form-item
                    prop="description_eng"
                    label="Рестораны танилцуулга(Англи) "
                  >
                    <el-input
                      v-model="data[type].description_eng"
                      type="textarea"
                      :rows="2"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="panel-item">
              <header>Хаяг холбоо барих</header>
              <el-row :gutter="20" class="mb30">
                <el-col :span="12" :class="checkDiff('address')">
                  <el-form-item prop="address" label="Хаяг монгол">
                    <el-input
                      v-model="data[type].address"
                      type="textarea"
                      :rows="2"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mb30">
                <el-col :span="12" :class="checkDiff('phone_list')">
                  <el-form-item label="Утас">
                    <el-table
                      :data="data[type].phone_list"
                      style="width: 100%"
                      max-height="350"
                    >
                      <el-table-column
                        prop="name"
                        label="Албан тушаал"
                        width="150"
                      >
                        <template slot-scope="scope">
                          <el-input
                            size="small"
                            placeholder="Менежер"
                            style="text-align:center"
                            v-model="scope.row.name"
                            controls-position="right"
                            disabled
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column prop="phone" label="Утас" width="150">
                        <template slot-scope="scope">
                          <el-input
                            size="small"
                            placeholder="99119911"
                            style="text-align:center"
                            type="number"
                            :min="8"
                            :max="8"
                            v-model.number="scope.row.phone"
                            disabled
                          ></el-input>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :class="checkDiff('email')">
                  <el-form-item prop="email" label="И-мэйл">
                    <el-input
                      v-model="data[type].email"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: 'DetailComponent',
  props: {
    data: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: () => null
    }
  },
  methods: {
    checkDiff (field) {
      if (
        JSON.stringify(this.data.old[field]) ===
        JSON.stringify(this.data.new[field])
      ) {
        return ''
      } else {
        return 'diffHistory'
      }
    }
  }
}
</script>

<style>
.aa {
  color: green;
}
.diffHistory {
  /* background-color: #E4EBE4;  */
  border-style: dotted;
}
</style>
